<template>
  <div class="questions-type-preview">
    <template v-if="!editMode">
      <h3>{{ title }}</h3>
      <p>{{ subTitle }}</p>
    </template>

    <div
      class="questions-type-preview__example input-group"
      :class="{
        'questions-type-preview__example--builder': builderMode,
        'questions-type-preview__example--edit': editMode,
      }"
    >
      <slot></slot>
    </div>
    <p class="questions-type-preview__description">
      <small>{{ description }}</small>
    </p>
  </div>
</template>

<script>
export default {
  name: "QuestionsTypePreview",
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    description: {
      type: String,
    },
    builderMode: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    langObj: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.questions-type-preview {
  h3 {
    margin-bottom: 0;
  }
  &__example {
    background: #f7f7f7;
    padding: 19px 26px 26px;
    position: relative;
    margin: 24px 0 12px;
    &--builder {
      background: #fafafa;
    }
    &--edit {
      padding: 20px 26px 26px;
      margin-top: 0;
    }
  }
  &__example-title {
    position: absolute;
    left: 0;
    top: -25px;
    font-size: 13px;
    font-weight: 500;
    color: var(--hsgreyDark);
  }
  &__description {
    line-height: 16px;
  }
}
</style>
