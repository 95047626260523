<template>
  <div
    class="product-item"
    :disabled="loading || disabled"
    @click="$emit('item-click')"
  >
    <div class="product-item__image">
      <img v-if="product.ImageURL" :src="product.ImageURL" alt="" />
      <span class="no-image" v-else>No image</span>
    </div>

    <div class="product-item__info">
      <h3>{{ product.productName || product.ProductName }}</h3>
      <span
        >Weight:
        <strong>{{ product.weight || product.Weight || "/" }}</strong></span
      >
      <span
        >Price:
        <strong>{{ product.price || product.Price || "/" }}</strong></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  components: {},
  props: {
    product: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.product-item {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s ease all;
  &:hover {
    border-color: var(--red);
  }
  &[disabled]:not(.selected) {
    pointer-events: none;
    opacity: 0.6;
  }
  &.selected {
    border-color: var(--red);
    box-shadow: 0 0 0 3px var(--red);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 13px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      strong {
        font-weight: 500;
      }
    }
  }
  &__image {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 51%;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    margin-bottom: 18px;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      height: 90%;
      object-fit: contain;
    }
  }
}
</style>
