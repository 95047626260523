<template>
  <div class="question-builder">
    <div class="question-builder__content input-group">
      <div class="mb-15">
        <label
          for=""
          v-html="langObj['s4-customQuestions'].modalLabel1"
        ></label>
        <a-textarea
          v-model.trim="questionData.questionText"
          :auto-size="{ minRows: 2 }"
        />
      </div>

      <div class="question-builder__options">
        <label for=""
          ><span
            v-html="langObj['s4-customQuestions'].modalPlaceholder1"
          ></span>
          <span class="flex items-center">
            <span
              class="
                text-btn text-left
                flex
                items-center
                gap-5
                mr-20
                font-size-13
              "
              @click="bulkAddModal = true"
            >
              <icon-base
                :width="15"
                :height="15"
                :viewBox1="24"
                :viewBox2="24"
                iconName="plus"
                iconStroke="transparent"
                ><icon-plus
              /></icon-base>
              Bulk Add
            </span>
            <span
              >{{
                questionData.options.filter((item) => item.optionText !== "")
                  .length
              }}/{{ maxOptions }}</span
            >
          </span></label
        >

        <div
          class="question-builder__options-list"
          :class="{
            'ant-input-group-addon-small': questionData.options.length < 3,
          }"
        >
          <draggable
            class="list-group"
            tag="ul"
            v-model="questionData.options"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            handle=".ant-btn--drag"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <a-input
                v-for="(option, index) in questionData.options"
                placeholder="Add option text"
                class="options-input"
                :key="index"
                v-model="option.optionText"
                @pressEnter="addOption(index)"
              >
                <a-button
                  slot="addonAfter"
                  class="ant-btn--drag"
                  v-if="
                    questionData.options.length > 2 &&
                    index !== questionData.options.length - 1 &&
                    !editMode
                  "
                >
                  <font-awesome-icon icon="grip-vertical" />
                </a-button>
                <a-button
                  slot="addonAfter"
                  v-if="
                    option.optionText === '' ||
                    (questionData.options[index + 1] === undefined &&
                      index + 1 < maxOptions)
                  "
                  @click="addOption(index)"
                  v-html="langObj['s4-customQuestions'].modalButton3"
                >
                </a-button>
                <a-button
                  v-else
                  slot="addonAfter"
                  class="ant-btn--delete"
                  @click="removeOption(index)"
                  v-html="langObj['s4-customQuestions'].modalButton4"
                >
                </a-button>
              </a-input>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div class="question-builder__cta">
      <span class="question-builder__cta-info">{{ successInfoMessage }}</span>
      <div>
        <a-tooltip
          placement="top"
          overlayClassName="ant-tooltip--small"
          :visible="cancelTooltip"
        >
          <template slot="title">
            <span
              v-html="langObj['s4-customQuestions'].modalCancelTooltip"
            ></span>
          </template>
          <a-button
            @mouseenter="setCancelTooltip"
            @mouseleave="cancelTooltip = false"
            @click="$emit('question-builde:close')"
            v-html="langObj['s4-customQuestions'].modalButton1"
          >
          </a-button>
        </a-tooltip>
        <a-button
          type="primary"
          @click="saveData"
          :disabled="disableAddBtn"
          v-html="langObj['s4-customQuestions'].modalButton2"
        >
        </a-button>
      </div>
    </div>

    <a-modal
      title="Bulk Add "
      :visible="bulkAddModal"
      @ok="bulkAdd"
      @cancel="bulkAddModal = false"
      cancelText="Cancel"
      okText="Add"
    >
      <div class="input-group position-r">
        <label
          >Please copy and paste your full list with up to 10 items below. Make
          sure you paste them in a vertical list format, with one item per
          row.</label
        >
        <a-textarea v-model="bulkAddContent" :auto-size="{ minRows: 8 }" />
        <div v-if="errorMessage" class="validation">{{ errorMessage }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import IconBase from "@/components/general/IconBase.vue";
import IconPlus from "@/components/icons/IconPlus.vue";

export default {
  name: "MultiOptionsTypeBuilder",
  components: {
    IconBase,
    IconPlus,
    draggable,
  },
  props: {
    editMode: {
      type: Boolean,
    },
    questionInfo: {
      type: Object,
    },
    questionIndex: {
      type: Number,
    },
    type: {
      type: String,
    },
    sort: {
      type: String,
    },
    langObj: {
      type: Object,
    },
  },

  data() {
    return {
      successInfoMessage: null,
      cancelTooltip: false,
      maxOptions: 10,
      drag: false,
      questionData: {
        questionType: null,
        questionText: null,
        subQuestions: [],
        options: [{ optionText: "" }],
        sortOption: this.sort,
      },
      bulkAddModal: false,
      bulkAddContent: "",
      errorMessage: "",
    };
  },

  methods: {
    addOption(index) {
      if (this.questionData.options[index].optionText !== "") {
        if (this.questionData.options.length < this.maxOptions) {
          this.questionData.options.push({ optionText: "" });
          setTimeout(() => {
            const optionInputs = document.querySelectorAll(
              ".options-input input"
            );
            optionInputs[this.questionData.options.length - 1].focus();
          }, 200);
        }
      }
    },
    removeOption(index) {
      if (this.questionData.options.length === 1) {
        this.questionData.options[0] = { optionText: "" };
      } else {
        this.questionData.options.splice(index, 1);
      }
    },
    saveData() {
      let getOptions = this.questionData.options
        .filter((item) => item.optionText !== "")
        .map((item) => {
          return { ...item };
        });
      this.questionData.options = getOptions;
      this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave1;
      this.$emit("question-data", this.questionData, this.questionIndex);
      this.questionData = {
        questionType: null,
        questionText: null,
        subQuestions: [],
        options: [{ optionText: "" }],
        sortOption: this.sort,
      };
      setTimeout(() => {
        this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave2;
      }, 500);
      setTimeout(() => {
        this.successInfoMessage = null;
      }, 1500);
    },
    setCancelTooltip() {
      if (
        this.questionData.questionText !== null &&
        this.questionData.questionText !== "" &&
        this.questionData.options.filter((e) => e.optionText !== "").length > 1
      ) {
        this.cancelTooltip = true;
        setTimeout(() => {
          this.cancelTooltip = false;
        }, 2000);
      } else {
        this.cancelTooltip = false;
      }
    },
    bulkAdd() {
      this.errorMessage = "";
      const items = this.bulkAddContent
        .split("\n")
        .map((item) => item.trim())
        .filter((item) => item !== "");
      const questionDataLength = this.questionData.options.filter(
        (item) => item.optionText !== ""
      ).length;

      if (items.length + questionDataLength > this.maxOptions) {
        this.errorMessage = `Woops you've added too many items. Please remove ${
          items.length + questionDataLength - this.maxOptions
        }  items to continue.`;
      } else {
        this.questionData.options = this.questionData.options.filter(
          (item) => item.optionText !== ""
        );
        this.questionData.options.push(
          ...items.map((item) => ({
            optionText: item,
          }))
        );
        this.bulkAddModal = false;
        this.bulkAddContent = "";
      }
    },
  },
  computed: {
    disableAddBtn() {
      return (
        this.questionData.questionText === null ||
        this.questionData.questionText === "" ||
        this.questionData.options.filter((e) => e.optionText !== "").length < 2
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal) {
        this.questionData = {
          questionType: newVal,
          questionText: null,
          subQuestions: [],
          options: [{ optionText: "" }],
          sortOption: this.sort,
        };
      },
    },
  },
  mounted() {
    if (this.editMode) {
      this.questionData = this.questionInfo.CustomQuestions[this.questionIndex];
    }
  },
};
</script>
