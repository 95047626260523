<template>
  <div>
    <div class="questions-list-preview__cta flex">
      <a-checkbox v-model="localSaveInLibrary" v-if="showSaveInLibrary">
        Save in library</a-checkbox
      >
      <a-checkbox v-model="localSetAsScreener" v-if="showSetAsScreener">
        Set as Screener</a-checkbox
      >
      <a-tooltip
        placement="top"
        overlayClassName="ant-tooltip--small"
        v-if="!hideEditButton"
      >
        <template slot="title"> Edit question </template>
        <button
          class="btn-reset questions-list-preview__item-cta"
          @click="$emit('editItem'), (deleteInfo = false)"
        >
          <font-awesome-icon icon="pencil-alt" /> <span>Edit</span>
        </button>
      </a-tooltip>
      <a-tooltip
        placement="top"
        overlayClassName="ant-tooltip--small"
        v-if="!hideAddButton"
      >
        <template slot="title"> Add question</template>
        <button
          class="
            btn-reset
            questions-list-preview__item-cta
            questions-list-preview__item-cta--add
            flex
            items-center
          "
          :class="{ ' pointer-events-none': insertCustomQuestionsLoading }"
          @click="$emit('addSavedQuestion'), (addClicked = true)"
        >
          <Loader
            class="space-0"
            v-if="insertCustomQuestionsLoading && addClicked"
          ></Loader>
          <icon-base
            v-else
            :width="16"
            :height="16"
            :viewBox1="24"
            :viewBox2="24"
            iconName="plus"
            iconStroke="transparent"
            ><icon-plus
          /></icon-base>
          <span>Add</span>
        </button>
      </a-tooltip>
      <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
        <template slot="title"> Delete question </template>
        <button
          type="button"
          class="btn-reset questions-list-preview__item-cta"
          @click="deleteInfo = true"
        >
          <font-awesome-icon icon="trash-alt" /> <span>Delete</span>
        </button>
      </a-tooltip>
    </div>
    <div
      class="questions-list-preview__delete-info"
      :class="{
        'questions-list-preview__delete-info--is-active': deleteInfo,
      }"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <h4 v-html="langObj['s4-customQuestions'].previewDeleteMessage"></h4>
      <div class="questions-list-preview__delete-info-cta flex">
        <button
          type="button"
          class="btn-reset questions-list-preview__item-cta"
          @click="deleteInfo = false"
          v-html="langObj['s4-customQuestions'].previewDeleteNo"
        ></button>
        <button
          type="button"
          class="
            btn-reset
            questions-list-preview__item-cta
            questions-list-preview__item-cta--delete
          "
          @click="$emit('deleteTempQuestion'), (deleteInfo = false)"
          v-html="langObj['s4-customQuestions'].previewDeleteYes"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import Loader from "@/components/general/Loader.vue";
export default {
  name: "QuestionSettings",
  components: {
    IconBase,
    IconPlus,
    Loader,
  },
  props: {
    langObj: {
      type: Object,
    },
    setAsScreener: {
      type: Boolean,
    },
    showSetAsScreener: {
      type: Boolean,
    },
    saveInLibrary: {
      type: Boolean,
    },
    showSaveInLibrary: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    hideAddButton: {
      type: Boolean,
    },
    insertCustomQuestionsLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      deleteInfo: false,
      localSetAsScreener: this.setAsScreener,
      localSaveInLibrary: this.saveInLibrary,
      addClicked: false,
    };
  },
  methods: {},
  watch: {
    localSetAsScreener(newVal) {
      this.$emit("setAsScreener", newVal);
    },
    localSaveInLibrary(newVal) {
      this.$emit("saveInLibrary", newVal);
    },
    insertCustomQuestionsLoading(newVal) {
      if (!newVal) {
        this.addClicked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
