<template>
  <div class="stimuli-title">
    <h2 v-html="title" :style="{ maxWidth: maxWidth + 'px' }"></h2>
  </div>
</template>

<script>
export default {
  name: "StimuliTitle",
  components: {},
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    maxWidth: {
      type: Number,
      default() {
        return 800;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.stimuli-title {
  margin-bottom: 15px;
  border-radius: 5px;
  background: #f4f7fe;
  padding: 8px 12px 8px;
  min-height: 56px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 980px) {
    // margin-bottom: 35px;
  }
  h2 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    font-weight: 500;
    strong {
      font-weight: 600;
    }
    @media screen and (max-width: 980px) {
      font-size: 16px;
      line-height: 26px;
    }
    @media screen and (max-width: 800px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
