var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"authoring-wrapper"},[_c('div',{staticClass:"flex h-full"},[_c('ProgressBar',{attrs:{"stepIndex":_vm.stepIndex,"stepsType":"pack","surveyID":_vm.surveyID}}),_c('section',{staticClass:"wrapper"},[_c('a-breadcrumb',{staticClass:"breadcrum breadcrum--authoring"},[(_vm.user !== null && _vm.user.SkinIdentifier !== 'pg')?[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.client.ClientName))])]),_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"/products"}},[_vm._v("Products")])]),_c('a-breadcrumb-item',[_vm._v("Pack Test")])]:_vm._e()],2),_c('div',{ref:"parent",staticClass:"wrapper__inner"},[(_vm.activeStep === 0)?_c('Loader',{attrs:{"text":"Loading...","paddingTop":150,"paddingBottom":80}}):_vm._e(),(_vm.activeStep !== 0)?_c('ShelfUpload',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 1),expression:"activeStep === 1"}],attrs:{"shelfUploadData":_vm.shelfUploadData,"currency":_vm.productsDetails.currency},on:{"nextStep":function($event){_vm.emptyBrandPriceProducts.length > 0
              ? (_vm.activeStep = 2)
              : (_vm.activeStep = 3),
              _vm.scrollToTop()},"scroll-top":_vm.scrollToURL,"products-list":function($event){_vm.productsList = $event},"empty-brand-price-products":function($event){_vm.emptyBrandPriceProducts = $event},"unique-brands":function($event){_vm.uniqueBrands = $event},"active-currency":_vm.setActiveCurrency}}):_vm._e(),(_vm.activeStep !== 0)?_c('EditProductsList',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 2),expression:"activeStep === 2"}],attrs:{"productsList":_vm.emptyBrandPriceProducts,"currency":_vm.activeCurrency || _vm.productsDetails.currency},on:{"prevStep":function($event){(_vm.activeStep = 1), _vm.scrollToTop()},"nextStep":function($event){(_vm.activeStep = 3), _vm.scrollToTop()}}}):_vm._e(),(_vm.activeStep !== 0)?_c('ProductsList',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 3),expression:"activeStep === 3"}],attrs:{"activeShelf":_vm.activeShelf,"selectedProductsKeys":_vm.selectedRowKeys,"selectedProducts":_vm.chosenProducts,"shelfImageId":_vm.shelfImageId,"currentBrand":_vm.activeBrand,"currentShelfId":_vm.currentShelfId,"surveyBrand":_vm.surveyInfo.brand,"productsList":_vm.productsList,"uniqueBrandList":_vm.uniqueBrands,"currency":_vm.activeCurrency || _vm.productsDetails.currency},on:{"brand":function($event){_vm.activeBrand = $event},"prevStep":function($event){_vm.emptyBrandPriceProducts.length > 0
              ? (_vm.activeStep = 2)
              : (_vm.activeStep = 1),
              _vm.scrollToTop()},"nextStep":function($event){(_vm.activeStep = 4), _vm.scrollToTop()},"products-details":function($event){_vm.chosenProducts = $event},"brandChanged":_vm.brandChanged,"brand-list":function($event){_vm.brandList = $event}}}):_vm._e(),_c('ChosenProductsList',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 4),expression:"activeStep === 4"}],attrs:{"chosenProductsList":_vm.chosenProducts,"unEncryptSurveyId":_vm.unEncryptSurveyId,"surveyID":_vm.surveyID,"shelfID":_vm.activeShelf
              ? _vm.activeShelf.shelfID
              : _vm.productsDetails.TemplateShelfID,"activeBrand":_vm.activeBrand,"activeShelf":_vm.activeShelf,"activeDeepDiveStep":_vm.activeDeepDiveStep,"activeStep":_vm.activeStep,"currency":_vm.activeCurrency || _vm.productsDetails.currency,"productsDetailsInfo":_vm.productsDetails,"editableSurvey":_vm.editableSurvey},on:{"setProductsDetails":_vm.setProductsDetails,"editShelf":function($event){_vm.activeStep = 1},"prevStep":function($event){(_vm.activeStep = 3), _vm.scrollToTop()},"nextStep":function($event){_vm.activeDeepDiveStep
              ? (_vm.activeStep = 5)
              : ((_vm.activeStep = 6), _vm.setDeepDiveProducts()),
              _vm.scrollToTop()}}}),(_vm.activeStep === 5)?_c('DeepDive',{attrs:{"chosenProductsList":_vm.chosenProducts,"productsDetailsInfo":_vm.productsDetails},on:{"setDeepDive":_vm.setDeepDive,"prevStep":function($event){(_vm.activeStep = 4), _vm.scrollToTop()},"nextStep":function($event){(_vm.activeStep = 6), _vm.setDeepDiveProducts(), _vm.scrollToTop()}}}):_vm._e(),(_vm.activeStep !== 0 && _vm.activeStep !== 1 && _vm.activeStep !== 2)?_c('CompetitiveBenchmark',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeStep === 6),expression:"activeStep === 6"}],attrs:{"deepDiveProducts":_vm.deepDiveProducts,"brandList":_vm.uniqueBrands,"activeBrand":_vm.activeBrand,"activeStep":_vm.activeStep,"productsDetailsInfo":_vm.productsDetails,"editableSurvey":_vm.editableSurvey,"activeDeepDiveStep":_vm.activeDeepDiveStep,"chosenProducts":_vm.chosenProducts,"productsList":_vm.productsList,"nextDisable":_vm.nextBtnDisable},on:{"prevStep":function($event){_vm.activeDeepDiveStep ? (_vm.activeStep = 5) : (_vm.activeStep = 4),
              _vm.scrollToTop()},"competitive-products":_vm.setCompetitiveProducts,"disable-next-step":function($event){_vm.nextDisable = $event},"enable-deep-dive":_vm.enableDeepDive,"nextStep":function($event){_vm.nextStep(), _vm.scrollToTop()}}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }