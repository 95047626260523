import { render, staticRenderFns } from "./QuestionsListPreviewItem.vue?vue&type=template&id=3128ef7c&scoped=true&"
import script from "./QuestionsListPreviewItem.vue?vue&type=script&lang=js&"
export * from "./QuestionsListPreviewItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3128ef7c",
  null
  
)

export default component.exports