<template>
  <div class="questions-list-preview__item">
    <h4 v-html="langObj['s4-customQuestions'].previewTitle1"></h4>
    <span>{{ getQuestionType() }}</span>

    <h4><span v-html="langObj['s4-customQuestions'].modalLabel1"></span></h4>
    <span>{{ item.questionText }}</span>

    <template v-if="item.subQuestions !== null">
      <h4 v-html="langObj['s4-customQuestions'].previewTitle2"></h4>
      <ol class="questions-list-preview__answers">
        <li v-for="(item, index) in item.subQuestions" :key="index">
          {{ item.subQuestionText }}
        </li>
      </ol>
    </template>

    <template v-if="item.options.length">
      <h4 v-html="langObj['s4-customQuestions'].previewTitle3"></h4>
      <ol class="questions-list-preview__answers">
        <li v-for="(item, index) in item.options" :key="index">
          {{ item.optionText }}
        </li>
      </ol>
    </template>

    <button
      type="button"
      class="btn-reset questions-list-preview__item-cta"
      @click="editItem"
      v-html="langObj['s4-customQuestions'].editBtn"
    ></button>

    <button
      type="button"
      class="
        btn-reset
        questions-list-preview__item-cta
        questions-list-preview__item-cta--delete
      "
      @click="deleteInfo = true"
      v-html="langObj['s4-customQuestions'].modalButton4"
    ></button>

    <div
      class="questions-list-preview__delete-info"
      :class="{
        'questions-list-preview__delete-info--is-active': deleteInfo,
      }"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <h4 v-html="langObj['s4-customQuestions'].previewDeleteMessage"></h4>
      <div class="questions-list-preview__delete-info-cta flex">
        <button
          type="button"
          class="btn-reset questions-list-preview__item-cta"
          @click="deleteInfo = false"
          v-html="langObj['s4-customQuestions'].previewDeleteNo"
        ></button>
        <button
          type="button"
          class="
            btn-reset
            questions-list-preview__item-cta
            questions-list-preview__item-cta--delete
          "
          @click="$emit('deleteTempQuestion'), (deleteInfo = false)"
          v-html="langObj['s4-customQuestions'].previewDeleteYes"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionsListPreviewItem",
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    langObj: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      deleteInfo: false,
    };
  },

  methods: {
    editItem() {
      let info = {
        index: this.index,
        questionType: this.item.questionType,
      };

      this.$emit("editQuestion", info);
    },
  },
  computed: {
    getQuestionType() {
      const text = () => {
        switch (this.item.questionType) {
          case "text":
            return "Text";
          case "radio":
            return "Single choice";
          case "checkbox":
            return "Multi choice";
          case "rank-dragdrop":
            return "Rank";
          case "table-radio":
            return "Grid";
          default:
            return "/";
        }
      };
      return text;
    },
  },
};
</script>

<style lang="scss" scoped></style>
