var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stimuli-summary"},[_c('div',{staticClass:"page-title"},[_c('icon-base',{attrs:{"width":32,"height":33,"viewBox1":32,"viewBox2":33,"iconName":"chart","iconColor":"transparent"}},[_c('icon-chart-histogram')],1),_vm._m(0)],1),_c('div',{staticClass:"box-wrapper"},[_c('StimuliTitle',{attrs:{"title":"Define the priority product for packaging diagnostics. Standard approach includes sample for one package, but you can identify more for an incremental cost."}}),_c('div',{staticClass:"flex stimuli-summary-products"},_vm._l((_vm.productsDetailsInfo.zones),function(item,index){return _c('div',{key:item.packs[0].UPC,staticClass:"stimuli-summary-products__item",class:{
          'stimuli-summary-products__item--deep-dive': item.deepDive,
        }},[_c('div',{staticClass:"stimuli-summary-products__item-inner"},[_c('div',{staticClass:"stimuli-summary-products__item-info-wrapper"},[(item.deepDive)?_c('div',{staticClass:"stimuli-summary-products__item-flag"},[_vm._v(" Priority Product ")]):_vm._e(),_c('div',{staticClass:"stimuli-summary-products__item-image image-loading"},[_c('img',{attrs:{"data-v-2eca84ac":"","src":item.packs[0].ImageURL},on:{"load":function($event){return $event.target.parentNode.classList.toggle(
                    'image-loading--loaded'
                  )}}})]),_c('div',{staticClass:"stimuli-summary-products__item-info"},[_c('h3',[_vm._v(_vm._s(item.packs[0].ProductName))]),_c('span',[_vm._v("Weight: "),_c('strong',[_vm._v(_vm._s(item.packs[0].Weight))])]),_c('span',[_vm._v("Price: "),_c('strong',[_vm._v(_vm._s(item.packs[0].Price))])])])]),_c('div',{staticClass:"stimuli-summary-products__item-select"},[_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('setDeepDive', {
                    index: index,
                    deepDive: _vm.productsDetailsInfo.zones[index].deepDive
                      ? 'no'
                      : 'yes',
                  })}}},[(_vm.productsDetailsInfo.zones[index].deepDive)?_c('span',[_vm._v("Unselect")]):_c('span',[_vm._v("Select")])])],1)])])])}),0)],1),_c('div',{staticClass:"flex justify-between stimuli-nav"},[_c('a-button',{on:{"click":function($event){return _vm.$emit('prevStep')}}},[_c('icon-base',{staticClass:"ml-5 back",attrs:{"width":20,"height":20,"viewBox1":20,"viewBox2":20,"iconName":"longArrowRight","iconStroke":"#D04395"}},[_c('icon-long-arrow-right')],1),_vm._v(" Back")],1),_c('a-button',{attrs:{"type":"primary","disabled":_vm.disableNextStep === undefined},on:{"click":function($event){return _vm.$emit('nextStep')}}},[_vm._v(" Next "),_c('icon-base',{staticClass:"ml-5",attrs:{"width":20,"height":20,"viewBox1":20,"viewBox2":20,"iconName":"longArrowRight","iconStroke":"#fff"}},[_c('icon-long-arrow-right')],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title__text"},[_c('h1',[_vm._v("Stimuli")]),_c('span',[_vm._v("Define the priority product")])])}]

export { render, staticRenderFns }