var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-item",class:{
    'product-item--test': _vm.productInfo.FriendlyName !== 'Control',
    'product-item--no-image': _vm.productInfo.ImageURL === '',
  }},[_c('div',{staticClass:"product-item__inner"},[_c('div',{staticClass:"product-item__info-wrapper"},[_c('div',{staticClass:"product-item__image",class:{
          'product-item__image--small':
            _vm.productInfo.FriendlyName === 'Control',
        }},[_c('ImageUpload',{attrs:{"unEncryptSurveyId":_vm.unEncryptSurveyId,"image":_vm.productInfo.ImageURL},on:{"image:url":_vm.setImageUrl}},[_c('div',{staticClass:"product-item__image-upload-info"},[_c('span',[_vm._v("Drag and Drop image here "),_c('br'),_vm._v(" Or")]),_c('div',[_vm._v("Browse Files")])])])],1),(_vm.productInfo.FriendlyName === 'Control')?_c('div',{staticClass:"product-item__info"},[_c('h3',[_vm._v(_vm._s(_vm.productName))]),_c('span',[_vm._v("Weight: "),_c('strong',[_vm._v(_vm._s(_vm.productInfo.Weight))])]),_c('span',[_vm._v("Price: "),_c('strong',[_vm._v(_vm._s(_vm.productInfo.Price))])])]):_vm._e()]),_c('div',{staticClass:"product-item__select"},[_c('div',[_c('a-button',{staticClass:"w-full",attrs:{"type":"primary"},on:{"click":function($event){_vm.showForm = true}}},[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"pencil-alt"}}),_vm._v(" Edit "+_vm._s(_vm.productInfo.FriendlyName))],1)],1)]),_c('a-modal',{attrs:{"title":"Product details","visible":_vm.showForm,"footer":null,"width":350},on:{"cancel":function($event){_vm.showForm = false}}},[_c('div',{staticClass:"product-item__form"},[_c('a-row',{staticClass:"mb-10",attrs:{"gutter":16}},[_c('a-col',{staticClass:"product-item__form-item input-group input-group--small",attrs:{"span":24}},[_c('label',[_vm._v("Name*")]),_c('div',{staticClass:"position-r"},[_c('a-input',{attrs:{"placeholder":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1)])],1),_c('a-row',{class:{
            'mb-15': _vm.productInfo.FriendlyName === 'Control',
            'mb-25': _vm.productInfo.FriendlyName !== 'Control',
          },attrs:{"gutter":16}},[_c('a-col',{staticClass:"product-item__form-item input-group input-group--small",attrs:{"xs":{ span: 12 }}},[_c('label',[_vm._v("Price*")]),_c('div',{staticClass:"position-r flex items-center"},[_c('span',{staticClass:"mr-5 currency dark-color"},[_vm._v(_vm._s(_vm.currency))]),_c('a-input',{attrs:{"placeholder":"","type":"number"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)]),_c('a-col',{staticClass:"product-item__form-item input-group input-group--small",attrs:{"span":12}},[_c('label',[_vm._v("UPC*")]),_c('div',{staticClass:"position-r"},[_c('a-input',{attrs:{"placeholder":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.upc),callback:function ($$v) {_vm.upc=$$v},expression:"upc"}})],1)])],1),_c('a-row',{attrs:{"gutter":16}},[(_vm.productInfo.FriendlyName === 'Control')?_c('a-col',{staticClass:"product-item__form-item",attrs:{"span":24}},[_c('p',{staticClass:"font-size-13"},[_vm._v(" No need to make changes to the Control version of this product. Add a new version by clicking the Add button. ")])]):_vm._e()],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticClass:"product-item__form-item flex justify-end",attrs:{"span":24}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.showForm = false}}},[_vm._v(" Done")])],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }